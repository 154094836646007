body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.primary-font1{
  color: #1da57a;

}
.primary-font2{
  color: #d81e06;

}
.container {
  display: -webkit-flex;
  display: flex;
  height: 100%;
}
.container .nav-left {
  width: auto;
  height: calc(100vh);
  background: #3c3f41;
  border-right: 1px solid #4b4b4b;
}
.container .main {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.container .main .top {
  width: 100%;
  height: 100px;
}
.container .main .down {
  width: 100%;
  padding: 30px;
  -webkit-flex: 1 1;
          flex: 1 1;
  background: #fff;
  overflow: scroll;
  overflow-x: hidden;
}
.font1 {
  color: #a9b7c6;
}
.income-font {
  color: #1da57a;
  font-weight: bold;
}
.pay-font {
  color: #d81e06;
  font-weight: bold;
}

/** load **/
.ajax-loading {
  display: none;
}
.ajax-loading .loading {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 0 40px;
  height: 80px;
  line-height: 80px;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 6px;
  text-align: center;
  z-index: 9999;
  font-size: 16px;
  color: #fff;
}
.ajax-loading .loading img {
  width: 32px;
  vertical-align: middle;
}
.ajax-loading .loading span {
  margin-left: 12px;
}
.ajax-loading .overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9998;
  background: #ffffff;
  opacity: 0.1;
}
/****/

.header {
  width: 100%;
  background: #3c3f41;
  color: #a9b7c6;
  box-sizing: border-box;
  padding-left: 12px;
  border-bottom: 1px solid #4b4b4b;
  border-left: 1px solid #4b4b4b;
}
.header .header-left {
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  box-sizing: border-box;
  color: #a9b7c6;
}
.header .header-left .header-nav-title {
  margin-left: 20px;
}
.header .header-right {
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}
.header .qiut-login {
  padding-left: 20px;
  padding-right: 50px;
}
.ant-breadcrumb-link,
.ant-breadcrumb a,
.ant-breadcrumb-separator,
a {
  color: #a9b7c6;
}
a:hover,
.ant-breadcrumb a:hover {
  color: #fafafa;
}

.nav-left-logo-view {
  width: 100%;
  height: 100px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #fff;
}
.logo-name {
  color: #fff;
}
.nav-left-content-view,
.nav-left-content-wrap-view {
  position: relative;
  box-sizing: border-box;
}
.nav-left-content-view .header-collapse-view,
.nav-left-content-wrap-view .header-collapse-view {
  width: 100%;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  padding-right: 15px;
  box-sizing: border-box;
}
.nav-left-content-view .header-collapse-view .nav-left-toggle-collapsed-view,
.nav-left-content-wrap-view .header-collapse-view .nav-left-toggle-collapsed-view {
  background: #464a4c;
  border: 1px solid #a9b7c6;
}
.nav-left-content-view .menu-view,
.nav-left-content-wrap-view .menu-view {
  background: #3c3f41;
}
.nav-left-content-view {
  min-width: 220px;
}
.nav-left-content-wrap-view {
  width: auto;
  -webkit-align-items: center;
          align-items: center;
}

.ant-menu-dark,
.ant-menu-sub,
.antd-submenu-popup,
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background-color: #464a4c;
  box-shadow: none;
}
.ant-menu-item,
.ant-menu-dark .ant-menu-sub {
  background-color: #464a4c;
}
.ant-menu-item-active,
.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: #3c3f41;
}

.home-wrap {
  height: calc(85vh);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 20px;
  background: #fff;
}
.g2-view {
  width: 100%;
  max-height: 400px;
  box-sizing: border-box;
  margin-top: 30px;
  margin-bottom: 30px;
}

.form-login-div {
  width: 100%;
  height: 100%;
  background: #2b2b2b;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background: url("/assets/bg.gif");
}
.form-login-div .form-login {
  width: 450px;
  height: 300px;
  background: #464a4c;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 20px;
  margin-top: -50px;
  box-shadow: 5px 5px 20px #464a4c;
}
.form-login-div .form-login .loginform-button-style {
  width: 250px;
  background: #3c3f41;
}
.form-login-div .form-login .input-style {
  width: 250px;
}

.teacher-header-wrap {
  width: 100%;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}
.headimage-pic {
  width: 60px;
  height: 60px;
}
.table-view {
  width: 100%;
  height: 200px;
}
.form-search-div {
  height: 100px;
  width: 100%;
  background: #eee;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding-left: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.min-width-controll {
  width: auto;
  height: auto;
  min-width: 200px;
}
.min-width-nickname {
  min-width: 200px;
}

.content-wrap {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.form-teacher-add {
  width: 400px;
}
.form-teacher-add .layout-center {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
}
.form-teacher-add .avatar-pic {
  width: 80px;
  height: 80px;
}

.content-wrap {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.content-wrap .form-teacher-add {
  width: 400px;
}
.content-wrap .form-teacher-add .layout-center {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
}
.content-wrap .form-teacher-add .avatar-pic {
  width: 100px;
  height: 100px;
}

.teacher-header-wrap {
  width: 100%;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}
.course-cate-pic {
  width: 60px;
  height: 60px;
}
.table-view {
  width: 100%;
  height: 200px;
}
.form-search-div {
  height: 100px;
  width: 100%;
  background: #eee;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding-left: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.min-width-controll {
  width: auto;
  height: auto;
  min-width: 200px;
}

.content-wrap {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.content-wrap .form-teacher-add {
  width: 400px;
}
.content-wrap .form-teacher-add .layout-center {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
}
.content-wrap .form-teacher-add .course-cate-pic {
  width: 60px;
  height: 60px;
}

.content-wrap {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.content-wrap .form-teacher-add {
  width: 400px;
}
.content-wrap .form-teacher-add .layout-center {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
}
.content-wrap .form-teacher-add .course-cate-pic {
  width: 60px;
  height: 60px;
}

.teacher-header-wrap {
  width: 100%;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}
.course-cover-pic {
  width: 140px;
  height: 80px;
}
.table-view {
  width: 100%;
  height: 200px;
}
.form-search-div {
  height: 100px;
  width: 100%;
  background: #eee;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding-left: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.course-input-style {
  width: 300px;
}
.min-width-controll {
  width: auto;
  height: auto;
}
.green-font {
  color: #1DA57A;
}

.course-content-wrap {
  padding: 15px;
  height: 100%;
  overflow-y: scroll;
}
.course-content-wrap .course-form-teacher-add {
  width: 100%;
  height: 100%;
}
.course-content-wrap .course-form-teacher-add .layout-center {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
}
.course-content-wrap .course-form-teacher-add .course-wrapper {
  background: #f6f7f8;
  min-height: 300px;
  max-height: 500px;
  height: auto;
}
.course-content-wrap .course-form-teacher-add .course-input-style {
  width: 300px;
}
.course-content-wrap .course-form-teacher-add .course-avatar-pic {
  width: 140px;
  height: 80px;
}
.price-rule-row-view {
  width: 100%;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  color: #333;
  box-sizing: border-box;
  padding-left: 30px;
}
.price-rule-font {
  margin-left: 30px;
}

.course-content-wrap .course-form-teacher-add {
  width: 100%;
}
.course-content-wrap .course-form-teacher-add .layout-center {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
}
.course-content-wrap .course-form-teacher-add .course-wrapper {
  background: #f6f7f8;
  min-height: 300px;
  max-height: 500px;
  height: auto;
}
.course-content-wrap .course-form-teacher-add .course-wrapper p,
.course-content-wrap .course-form-teacher-add .course-wrapper span {
  padding: 0;
  margin: 0;
}
.course-content-wrap .course-form-teacher-add .course-input-style {
  width: 300px;
}
.course-content-wrap .course-form-teacher-add .course-avatar-pic {
  width: 140px;
  height: 80px;
}
.course-content-wrap .course-form-teacher-add .course-textarea-style {
  width: 600px;
  height: 200px;
}

.cs-header-view {
  width: 100%;
  height: 100px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.cs-header-view .cs-header-row {
  -webkit-flex: 1 1;
          flex: 1 1;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.cs-font2 {
  font-size: 24px;
  margin-top: 5px;
  font-weight: bold;
}
.canvas-view {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.canvas-view .g2-max-view {
  -webkit-flex: 1 1;
          flex: 1 1;
  height: 100%;
}

.orderinfo-header-wrap {
  width: 100%;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}
.orderinfo-header-detail {
  margin-right: 30px;
}
.headimage-pic {
  width: 60px;
  height: 60px;
}
.table-view {
  width: 100%;
  height: 200px;
}
.form-search-div {
  height: 100px;
  width: 100%;
  background: #eee;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding-left: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.min-width-controll {
  width: auto;
  height: auto;
  min-width: 200px;
}

.teacher-header-wrap {
  width: 100%;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}
.headimage-pic {
  width: 60px;
  height: 60px;
  border-radius: 100%;
}
.table-view {
  width: 100%;
  height: 200px;
}
.button-padding {
  margin-bottom: 10px;
}
.form-search-div {
  height: 100px;
  width: 100%;
  background: #eee;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding-left: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.min-width-controll {
  width: auto;
  height: auto;
  min-width: 200px;
  display: -webkit-flex;
  display: flex;
}

.teacher-header-wrap {
  width: 100%;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}
.headimage-pic {
  width: 60px;
  height: 60px;
  border-radius: 100%;
}
.table-view {
  width: 100%;
  height: 200px;
}
.button-padding {
  margin-bottom: 10px;
}
.form-search-div {
  height: 100px;
  width: 100%;
  background: #eee;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding-left: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.min-width-controll {
  width: auto;
  height: auto;
  min-width: 200px;
  display: -webkit-flex;
  display: flex;
}
.input-style {
  width: 300px;
}
.comment-content-view {
  width: 300px;
}

.teacher-header-wrap {
  width: 100%;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}
.headimage-pic {
  width: 60px;
  height: 60px;
}
.table-view {
  width: 100%;
  height: 200px;
}
.form-search-div {
  height: 100px;
  width: 100%;
  background: #eee;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding-left: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.min-width-controll {
  width: auto;
  height: auto;
  min-width: 200px;
}

.content-wrap {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.content-wrap .setting-form-teacher-add {
  width: 300px;
}
.content-wrap .setting-form-teacher-add .layout-center {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
}
.content-wrap .setting-form-teacher-add .avatar-pic {
  width: 100px;
  height: 100px;
}
.content-wrap .setting-form-teacher-add .setting-label-div {
  height: 60px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  font-size: 18px;
  font-weight: bold;
  color: #000;
}

.content-wrap {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.content-wrap .setting-form-teacher-add {
  width: 300px;
}
.content-wrap .setting-form-teacher-add .layout-center {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
}
.content-wrap .setting-form-teacher-add .avatar-pic {
  width: 100px;
  height: 100px;
}
.server-qrcode-pic {
  width: 100px;
  height: 100px;
}

.teacher-header-wrap2 {
  width: 100%;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  padding-right: 50px;
}
.headimage-pic {
  width: 60px;
  height: 60px;
}
.table-view {
  width: 100%;
  height: 200px;
}
.form-search-div {
  height: 100px;
  width: 100%;
  background: #eee;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding-left: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.course-input-style {
  width: 300px;
}

.video-content-wrap {
  padding: 15px;
  height: 100%;
  overflow-y: scroll;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}
.video-content-wrap .course-form-video-add {
  width: 1000px;
  min-width: 300px;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.video-content-wrap .course-form-video-add .layout-center {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
}
.video-content-wrap .course-form-video-add .course-wrapper {
  background: #f6f7f8;
  height: 300px;
}
.video-content-wrap .course-form-video-add .course-input-style {
  width: 300px;
}
.video-content-wrap .course-form-video-add .avatar-pic {
  width: 100px;
  height: 100px;
}
.flex-row-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}
.video-content-wrapper {
  min-height: 300px;
  max-height: 500px;
  height: auto;
  background: #f6f7f8;
}
.form-item-width-custom {
  width: 450px;
}
.form-item-widthall {
  width: 100%;
}

.video-content-wrap .course-form-video-add .layout-center {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
}
.video-content-wrap .course-form-video-add .course-wrapper {
  background: #f6f7f8;
  min-height: 300px;
  max-height: 500px;
}
.video-content-wrap .course-form-video-add .course-input-style {
  width: 300px;
}
.video-content-wrap .course-form-video-add .avatar-pic {
  width: 100px;
  height: 100px;
}
.flex-row-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}
.video-content-wrapper {
  min-height: 300px;
  max-height: 500px;
  height: auto;
  background: #f6f7f8;
}
.form-item-width {
  width: 300px;
}
.form-item-widthall {
  width: 100%;
}

.teacher-header-wrap {
  width: 100%;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}
.headimage-pic {
  width: 60px;
  height: 60px;
}
.table-view {
  width: 100%;
  height: 200px;
}
.form-search-div {
  height: 100px;
  width: 100%;
  background: #eee;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding-left: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.min-width-controll {
  width: auto;
  height: auto;
  min-width: 200px;
}

.teacher-header-wrap {
  width: 100%;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}
.headimage-pic {
  width: 60px;
  height: 60px;
}
.table-view {
  width: 100%;
  height: 200px;
}
.form-search-div {
  height: 100px;
  width: 100%;
  background: #eee;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding-left: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.min-width-controll {
  width: auto;
  height: auto;
  min-width: 200px;
}

.teacher-header-wrap {
  width: 100%;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}
.headimage-pic {
  width: 60px;
  height: 60px;
}
.table-view {
  width: 100%;
  height: 200px;
}
.form-search-div {
  height: 100px;
  width: 100%;
  background: #eee;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding-left: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.min-width-controll {
  width: auto;
  height: auto;
  min-width: 200px;
}

.content-wrap {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.content-wrap .setting-form-teacher-add {
  width: 300px;
}
.content-wrap .setting-form-teacher-add .layout-center {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
}
.content-wrap .setting-form-teacher-add .avatar-pic {
  width: 100px;
  height: 100px;
}

.content-wrap {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.content-wrap .setting-form-teacher-add {
  width: 300px;
}
.content-wrap .setting-form-teacher-add .layout-center {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
}
.content-wrap .setting-form-teacher-add .avatar-pic {
  width: 100px;
  height: 100px;
}

.teacher-header-wrap {
  width: 100%;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}
.headimage-pic {
  width: 60px;
  height: 60px;
}
.table-view {
  width: 100%;
  height: 200px;
}
.form-search-div {
  height: 100px;
  width: 100%;
  background: #eee;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding-left: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.min-width-controll {
  width: auto;
  height: auto;
  min-width: 200px;
}

.content-wrap {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.content-wrap .setting-form-teacher-add {
  width: 300px;
}
.content-wrap .setting-form-teacher-add .layout-center {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
}
.content-wrap .setting-form-teacher-add .avatar-pic {
  width: 100px;
  height: 100px;
}

.content-wrap {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.content-wrap .setting-form-teacher-add {
  width: 300px;
}
.content-wrap .setting-form-teacher-add .layout-center {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
}
.content-wrap .setting-form-teacher-add .avatar-pic {
  width: 100px;
  height: 100px;
}

.teacher-header-wrap {
  width: 100%;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}
.headimage-pic {
  width: 60px;
  height: 60px;
}
.table-view {
  width: 100%;
  height: 200px;
}
.form-search-div {
  height: 100px;
  width: 100%;
  background: #eee;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding-left: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.min-width-controll {
  width: auto;
  height: auto;
  min-width: 200px;
}

.teacher-header-wrap {
  width: 100%;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}
.headimage-pic {
  width: 60px;
  height: 60px;
}
.table-view {
  width: 100%;
  height: 200px;
}
.form-search-div {
  height: 100px;
  width: 100%;
  background: #eee;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding-left: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.min-width-controll {
  width: auto;
  height: auto;
  min-width: 200px;
}

.teacher-header-wrap {
  width: 100%;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}
.headimage-pic {
  width: 60px;
  height: 60px;
}
.table-view {
  width: 100%;
  height: 200px;
}
.form-search-div {
  height: 100px;
  width: 100%;
  background: #eee;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding-left: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.min-width-controll {
  width: auto;
  height: auto;
  min-width: 200px;
}

.teacher-header-wrap {
  width: 100%;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}
.headimage-pic {
  width: 60px;
  height: 60px;
}
.table-view {
  width: 100%;
  height: 200px;
}
.form-search-div {
  height: 100px;
  width: 100%;
  background: #eee;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding-left: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.min-width-controll {
  width: auto;
  height: auto;
  min-width: 200px;
}

.teacher-header-wrap {
  width: 100%;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}
.headimage-pic {
  width: 60px;
  height: 60px;
}
.table-view {
  width: 100%;
  height: 200px;
}
.form-search-div {
  height: 100px;
  width: 100%;
  background: #eee;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding-left: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.min-width-controll {
  width: auto;
  height: auto;
  min-width: 200px;
}
.qrcode-window-view {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.qrcode-font1 {
  margin-bottom: 0.1rem;
}

.teacher-header-wrap {
  width: 100%;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}
.headimage-pic {
  width: 60px;
  height: 60px;
}
.table-view {
  width: 100%;
  height: 200px;
}
.form-search-div {
  height: 100px;
  width: 100%;
  background: #eee;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding-left: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.min-width-controll {
  width: auto;
  height: auto;
  min-width: 200px;
}
.qrcode-window-view {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.qrcode-font1 {
  margin-bottom: 0.1rem;
}
.coupon-font1 {
  color: #1DA57A;
}
.coupon-font2 {
  color: red;
}

.teacher-header-wrap {
  width: 100%;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}
.headimage-pic {
  width: 60px;
  height: 60px;
}
.table-view {
  width: 100%;
  height: 200px;
}
.form-search-div {
  height: 100px;
  width: 100%;
  background: #eee;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding-left: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.min-width-controll {
  width: auto;
  height: auto;
  min-width: 200px;
}

.user-headimage-pic {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
.user-row-view {
  width: 100%;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
}
.user-row-label-view {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}
.user-row-value-view {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
.card-content-view,
.card-content-view-down {
  border-bottom: none;
  width: 100%;
}
.transfer-style {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 30px;
  padding-bottom: 15px;
}
.coupon-font1 {
  color: #1DA57A;
}
.addform-view {
  overflow-y: scroll;
}
.hint-div-style {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #d81e06;
  padding: 15px;
  padding-top: 0;
}
.task-add-view {
  padding: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}
.window-view {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.window-view .window-header-view {
  width: 100%;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}
.window-view .window-close-view {
  margin-right: 40px;
}
.window-view .window-title-view {
  height: 100%;
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding-right: 100px;
  box-sizing: border-box;
  font-weight: bold;
}
.window-view .ant-card-body {
  -webkit-flex: 1 1;
          flex: 1 1;
  overflow: hidden;
  padding: 0;
  position: relative;
}
.window-view .ant-card-body .addform-view {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}
.window-view .ant-card-body .addform-view .form-top-view {
  width: 100%;
  -webkit-flex: 1 1;
          flex: 1 1;
  overflow: scroll;
  overflow-x: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  box-sizing: border-box;
  padding: 30px;
}
.window-view .ant-card-body .addform-view .form-top-view .step-view {
  width: 100%;
  padding: 30px;
}
.window-view .ant-card-body .addform-view .form-top-view .step-content-view {
  width: 100%;
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.window-view .ant-card-body .addform-view .form-down-view {
  width: 100%;
  height: 80px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-top: 1px solid #e8e8e8;
  padding: 0;
  margin: 0;
}

